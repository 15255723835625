<template>
    <div class="action-schedule">
        <template>
            <div class="message">
                <div class="message-top">
                    <img :src="require('@/assets/images/icon-hand.png')" alt="" />
                    <span class="message-title">{{ titleMessage }}</span>
                </div>
                <div class="message-content">
                    <div class="grey-box m-t-8">
                        <div class="user-profile">
                            <img :src="photoUrl" alt="" />
                            <div class="profile-info">
                                <div class="age" v-html="age" />
                                <div class="region" v-html="content.region" />
                                <div class="job" v-html="content.job" />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div v-if="cancelType === 'user'" class="content m-t-16">
                        <div class="desc li-dot m-b-16">
                            약속이 취소 처리되었습니다. 페널티 정책에 따라 취소 수수료가 부과될 예정입니다.
                        </div>
                        <div class="desc li-dot m-b-16">
                            <span class="reason" @click="showLink">부득이한 사유</span>에 해당하는 경우 증빙 자료를
                            고객센터에 제출해주세요. 감사합니다.
                        </div>
                    </div>
                    <div v-else class="content m-t-16">
                        <div class="desc li-dot m-b-16">
                            약속이 상대방의 요청으로 취소되었습니다. 약속이 중요한데 대단히 죄송합니다.
                        </div>
                        <div class="desc li-dot m-b-16">
                            상대방에게는 약속 취소에 따른 페널티가 부과될 예정입니다. 진정성있게 만남 가질 수 있는
                            문화를 위해 노력하겠습니다..!
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ActionScheduleBreakAdmin',
    props: ['message'],
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        photoUrl() {
            return this.content.urls[0] || null
        },
        age() {
            return this.$options.filters.asAgeInActionMessage(this.content.birthday)
        },
        cancelType() {
            if (this.content.msg.includes('완료')) {
                return 'user'
            } else {
                return 'target'
            }
        },
        titleMessage() {
            if (this.cancelType === 'user') {
                return '약속 취소 완료'
            } else {
                return '약속 취소 안내'
            }
        },
    },
    methods: {
        showLink() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: 'https://www.notion.so/vbproduct/61094531516c4b15b7ba235df79e9328?pvs=4',
            })
        },
    },
}
</script>
<style scoped lang="scss">
.message-title {
    font-size: 15px;
}
.reason {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}
.desc {
    font-size: 14px;
    &.li-dot {
        display: list-item;
        align-items: flex-start;
        margin-left: 16px;
    }
}
</style>
